let shortText = `Din când în când Simion Butunoiu strănută atât de zgomotos că toate femeile se întorc spăimântate, sau se pornește pe tusa lui obișnuită cu care de multe ori scoală noaptea tot satul din somn. Macedon așteaptă cuviincios până ce isprăvește dascălul cu tusea, apoi ia din fereastră o sticlă cu rachiu, închină foarte ceremonios, trage o dușcă zdravănă și trece băutura bătrânului care mormăie scurt ,,să ne ție Dumnezeu pe toți" și pune gârliciul la gură. Sticla pe urmă umblă din mână în mână.`;

let text = `— Cum, Ioane, treci așa parcă nici nu m-ai cunoaște? îi zise Vasile Baciu, neputându-se stăpâni, cu un rânjet acru. N-ai tu, băiete, nici un pic de rușine?
Pe prispă stăpânește Simion Butunoiu, care acum vreo douăzeci de ani a fost învățător în sat, iar azi mănâncă o pensie de cinci zloți pe lună și muncește la pământ mai abitir ca un flăcău. Împrejurul lui s-au adunat Macedon Cercetașu cu straja Cosma Ciocănaș, cu Simion Lungu, cu Toader Burlacu, cu Ștefan Ilina și cu alții, ascultând cu evlavie palavrele dascălului, auzite și răsauzite. Din când în când Simion Butunoiu strănută atât de zgomotos că toate femeile se întorc spăimântate, sau se pornește pe tusa lui obișnuită cu care de multe ori scoală noaptea tot satul din somn. Macedon așteaptă cuviincios până ce isprăvește dascălul cu tusea, apoi ia din fereastră o sticlă cu rachiu, închină foarte ceremonios, trage o dușcă zdravănă și trece băutura bătrânului care mormăie scurt ,,să ne ție Dumnezeu pe toți" și pune gârliciul la gură. Sticla pe urmă umblă din mână în mână. Macedon e cam cherchelit și comandă militărește tuturor să bea:

— Forverț, Simioane! Vaităr, Cosma!... Vaităr, vaităr!... A făcut oastea doisprezece ani, i-a fost dragă foc și, când e beat, ș-acuma numai în comenzi nemțești se ceartă cu nevastă-sa. Altminteri însă are o inimă de ceară și în douăzeci și opt de ani nu și-a bătut muierea niciodată; mai curând Floarea s-a întâmplat să-l muștruluiască.

Sticla ajunge la Toader Burlacu goală.
— Goală, ai? râde Macedon încântat. Goală?... Halt!... Haptac!... Unde ești, căprar de zi?... Forverț!...

Un copil de vreo zece ani sare drept în picioare pe prispă și strigă râzând:

— Hir!
— Nu râde la front, măgarule! se răstește Macedon. Na sticla și dă fuga la cantină la Avrum, să-ți mai dea o porție pentru mine!... Înțeles, căprar?

Căprarul însă refuză ordinul, răspunzând:
— A zis jupânul că nu-ți mai dă fără bani, bade Macedoane, nici să nu mă mai trimiți, că-i degeaba...

Macedon își saltă puțin mustața, încruntă din sprâncene privind aspru la copil, apoi se așază pe prispă clătinând din cap și oftând:

— Ehei, unde-i vremea când eram eu strajameșter[1]!... Atunci să fi poftit jupânul să nu-mi dea, că-i arătam eu supunere...

Mai boscorodește ceva, pe urmă încetează și se uită ursuz la nevastă-sa care are bani, dar nu-i dă, și care tăifăsuiește cu alte femei fără să se sinchisească de comenzile lui...

Tocmai atunci se târăște pe poartă, printre picioarele oamenilor, Savista, oloaga satului. E vară cu fata cea mai frumoasă din Pripas, cu Florica, și ceva neam cu nevasta lui Trifon Tătaru. Are picioarele încârcite din naștere, iar brațele lungi și osoase ca niște căngi anume spre a-și târî schilozenia, și o gură enormă cu buzele alburii de sub care se întind gingiile îmbălate, cu colți de dinți galbeni, rari și lungi. Trifon Tătaru o ține pe lângă casă, să-i vadă de copii. Când e vremea bună Savista șade în poartă, se ciorovăiește cu toți copiii din sat și primește pomana trecătorilor miloși... Sosește cu gălăgie mare. Gâgâie ceva cu glasul ei aspru, speriat. N-o bagă în seamă nimeni. Doar Trifon face un semn nevestei, care se apropie, repede, supărată:

— Da acasă nu puteai sta, măi femeie? Zău, parcă te mănâncă tălpile... Lași tu ograda pustie pe vremea asta... Și nici barem slugile nu-s la îndemână... Ce vrei? Ai? Spune ce vrei, Savistă?

Oloaga a făcut două ceasuri din Ulița cea mare, unde stătea Trifon, până aici. Începe să bâlbâie foarte grăbită și cu importanță, însoțindu-și sforțările cu niște gesturi desperate.

— Ce face? zice Maria lui Trifon, care se silește să ghicească ce spune. S-a bătut Vasile Baciu... Cu Avrum?... Cu Floarea Căruntu?... Ei ș-apoi? Ce-ți pasă ție, femeia lui Dumnezeu? Și pentru asta ai venit tu atâta cale? Vai de mine, că ești mai rea ca copiii cei fără minte... Uite ce ți-ai făcut poalele! Și numai azi te-am primenit... Of, bată-te...

Savista bombăne ceva ce ar vrea să însemne că ei nu-i pasă de poale, și pe urmă se pornește deodată pe un râs prostesc de bucurie.

— Cum ai zis?... Cine-i beat?... Vasile Baciu... Parcă azi s-a îmbătat întâi...

Maria o lasă rușinată și se întoarce între femei. Oloaga se târâie în mijlocul fetelor, se frământă să-și facă loc și privește apoi cu mare plăcere la horă. Are douăzeci și cinci de ani și tremură de fericire când aude lăutarii ori când vede vreo petrecere...

Savista n-are parte să se desfete multă vreme. Până să se așeze bine, până să se certe cu fetele pentru un loc mai bun, jocul încetează. Briceag, asudat și istovit, încheie Învârtita cu o apăsare zdravănă de arcuș, încât îi și plesnește o strună la vioară. Ion, feciorul Glanetașului, ținând de mijloc pe Ana lui Vasile Baciu, se repede la lăutari, rugându-i:

— Mai zi, mă Briceag... numai un pic, auzi? Un picuț de tot, țigane!

Alți flăcăi strigă poruncitor:
— Trage, țigane! Ce te codești?... De ce te plătim, cioara dracului?

Briceag aruncă o privire disprețuitoare spre cei ce-l ocărăsc, lui Ion însă îi răspunde convingător, arătându-i vioara:

— Nu mai pot, Ionică... Zău, nu mai pot... Crede-mă!... Mi-au amorțit degetele... Pe urmă și struna mi s-a rupt...

— Un pic, omule, nu-nțelegi? stăruie flăcăul. Țiganul însă se înfurie brusc, întoarce spatele, trântește vioara în brațele lui Holbea și începe să blesteme cătrănit:

— Fire-ar ale dracului toate ceterele din lume; plesnire-ar acolo unde-o fi cine m-a învățat să țin arcușul... Că io-i spun cu frumosul că nu pot și el mă omoară să mai zic... Dare-ar Dumnezeu să trăsnească toate jocurile cu pe cine le-a născocit...

Se despărțiră tot zâmbind, dar cu mai multă ură în suflet. Preotul își zicea că mai bine dăruiește locul oricărui țăran, decât să rămână în stăpânirea familiei dușmanilor lui ticăloși. Herdelea ar fi fost în stare să-i dea cu parul în cap omului care umblă să-i fure rodul muncii lui de ani de zile.

Boboteaza risipi orice nădejde de apropiere. Familia Herdelea aștepta să vie Belciug cu crucea și cu Iordanul, după datina creștinească. Preotul, începând din celalt capăt al satului, ajunse după-amiază în Ulița Mare. Îl văzură intrând la primarul Florea Tancu, apoi la Glanetașu, apoi ocolind casa învățătorului și trecând la Macedon Cercetașu, apoi îndreptându-se iar...

— Uite că nu vine la noi, murmură doamna Herdelea speriată întâi.

O indignare fără margini se încinse în casa învățătorului.
— Asta-i nemaipomenit! se cruci Titu. Un preot cumsecade nici n-ar visa asemenea murdărie!

— Acuma însă nu-l mai iert! se legătui Herdelea furios. Nu-l iert nici mort! Chiar azi am să-l reclam la episcopie... În toiul verii are să vie cu crucea, afurisitul!... De minunea lumii am să-l fac!

Învățătorul se așeză îndată să scrie plângerea. Ochelarii îi tremurau pe nas de mânie. Doamna Herdelea și Titu ocărau cu înverșunare.

— Lăsați-l pe mâna mea! strigă Herdelea de la masa de scris. Ne-a făcut el pocinogul, dar și eu am să-l joc să mă pomenească... Și să poftească să se atingă de casă, că-i arăt eu lui cine-s eu! Să poftească mortăciunea! Închid, pun lacăte și sigilii, și să îndrăznească să intre în lipsa noastră, să îndrăznească. Porcul și câinele de pămătuf!`

let exercitii = [

    {
        title: "Exercițiul nr. 1",
        author: "Ioan SLAVICI, Păcală în satul lui",
        text: "Și fiindcă românul zice că nu e nicăieri mai bine ca în satul lui, Păcală se întoarse și el în satul lui și începu cum încep toți oamenii care n-au nimic, adică făcu ce făcu de-și agonisi o vițelușă și o trimise la pășune în izlazul satului.\n" +
            "        Căci așa se făcea averea. Păscând, vițelușa se face vițea, vițeaua se face juncă, junca se face vacă, vaca fată, iar vaca cu vițelul o vinzi ca din prețul ei să cumperi șapte vițelușe și să le trimiți și pe ele la pășune în izlazul satului.\n",
    },
    {
        title: "Exercițiul nr. 2",
        author: "Ioan SLAVICI, Zâna Zorilor",
        text: "    Zânele începură a zâmbi vesel; florile începură a mirosi dulce; izvoarele deteră a curge limpede; vânturile se prefăcură în cântec de bucurie: roata vieții începu a se întoarce mai repede decât prisnelul; valul cel negru căzu la pământ și soarele strălucitor se ridică în sus către ceruri... sus... mai sus decât cum a fost cândva .. și-n lume se făcu lumina ca fața soarelui încât nouă ani, nouă luni și nouă zile oamenii nu văzură nimic de lumina cea înfricoșată.\n" +
            "    Petru merse acasă, aduse pe tatăl său cel bătrân și pe mama sa cea bătrâna, făcu o nuntă încât îi merse vestea-n nouăzeci și nouă de țări și se făcu împărat peste amândouă împărățiile.\n",
    },
    {
        title: "Exercițiul nr. 3",
        author: "Mihai EMINESCU, Făt-Frumos din lacrimă",
        text: "Cincizeci de ani de când împăratul purta război c-un vecin al lui. Murise vecinul și lăsase de moștenire fiilor și nepoților ura și vrajba de sânge. Cincizeci de ani, și numai împăratul trăia singur, ca un leu îmbătrânit, slăbit de lupte și suferințe — împărat, ce-n viața lui nu râsese niciodată, care nu zâmbea nici la cântecul nevinovat al copilului, nici la surâsul plin de amor al soției lui tinere, nici la poveștile bătrâne și glumețe ale ostașilor înălbiți în bătălie și nevoi. Se simțea slab, se simțea murind și n-avea cui să lese moștenirea urii lui. Trist se scula din patul împărătesc, de lângă împărăteasa tânără — pat aurit, însă pustiu și nebinecuvântat, — trist mergea la război cu inima neîmblânzită, — și împărăteasa sa, rămasă singură, plângea cu lacrimi de văduvie singurătatea ei.",
    },
    {
        title: "Exercițiul nr. 4",
        author: "Petre ISPIRESCU, Tinerețe fără bătrânețe și viață fără de moarte",
        text: "Cerând împăratului lucrurile ce-l povățuise calul, el a chemat pre vătaful curții și i-a dat poruncă ca să-i deschiză toate tronurile cu haine spre a-și alege fiul său pe acelea care îi va plăcea. Făt-Frumos, după ce răscoli trei zile și trei nopți, găsi în sfârșit, în fundul unui tron vechi, armele și hainele tatâne-său de când era flăcău, dar foarte ruginite. Se apucă însuși cu mâna lui să le curețe de rugină și, după șase săptămâni, izbuti a face să lucească armele ca oglinda. Totodată îngriji și de cal, precum îi zisese el. Destulă muncă avu; dar fie, că izbuti.\n" +
            "    Când auzi calul de la Făt-Frumos că hainele și armele sunt bine curățate și pregătite, odată se scutură și el, și toate bubele și răpciuga căzură de pe dânsul și rămase întocmai cum îl fătase mă-sa, un cal gras, trupeș și cu patru aripi; văzându-l Făt-Frumos astfel, îi zise:\n" +
            "    – De azi în trei zile plecăm.\n" +
            "– Să trăiești, stăpâne; sunt gata chiar azi, de poruncești, îi răspunse calul.\n",
    },
    {
        title: "Exercițiul nr. 5",
        author: "Petre ISPIRESCU, Greuceanu",
        text: "Pe vremea aceea, se afla un viteaz pe nume Greuceanu. Auzind și el de făgăduința împărătească, ce se gândi, ce se răzgândi, că numai își luă inima în dinți, " +
            "încumetându-se pe ajutorul lui Dumnezeu și pe voinicia sa, și plecă și el la împăratul să se închine cu slujba. Pe drum se întâlni cu doi oameni pe cari slujitorii" +
            " împărătești îi ducea la împăratul ca să-i taie, pentru că fugiseră de la o bătălie ce o avusese împăratul acesta cu niște gadine. Ei erau triști, bieții oameni, dară" +
            " Greuceanu îi mângâie cu niște vorbe așa de dulci, încât le mai veni nițică inimă, că era și meșter la cuvânt Greuceanu nostru.",
    },
    {
        title: "Exercițiul nr. 6",
        author: "Vasile ALECSANDRI, Iarna",
        text: "Din văzduh cumplita iarnă cerne norii de zăpadă,\n" +
            "    Lungi troiene călătoare adunate-n cer grămadă;\n" +
            "Fulgii zbor, plutesc în aer ca un roi de fluturi albi,\n" +
            "    Răspândind fiori de gheață pe ai țării umeri dalbi.\n" +
            "\n" +
            "    Ziua ninge, noaptea ninge, dimineața ninge iară!\n" +
            "    Cu o zale argintie se îmbracă mândra țară;\n" +
            "Soarele rotund și palid se prevede printre nori\n" +
            "Ca un vis de tinerețe printre anii trecători.\n" +
            "\n" +
            "    Tot e alb pe câmp, pe dealuri, împrejur, în depărtare,\n" +
            "    Ca fantasme albe plopii înșirați se pierd în zare,\n" +
            "    Și pe-ntinderea pustie, fără urme, fără drum,\n" +
            "    Se văd satele pierdute sub clăbucii albi de fum.\n" +
            "\n" +
            "    Dar ninsoarea încetează, norii fug, doritul soare\n" +
            "Strălucește și dezmiardă oceanul de ninsoare.\n" +
            "    Iată-o sanie ușoară care trece peste văi…\n" +
            "În văzduh voios răsună clinchete de zurgălăi.\n",
    },
    {
        title: "Exercițiul nr. 7",
        author: "Ion Luca CARAGIALE, Vizită",
        text: "Maiorul sare de gâtul mamei și o sărută... Mamei îi trece; iar eu, după ce am luat dulceața, mă pregătesc să sorb din cafea...\n" +
            "— Nu vă supără fumul de tutun? întreb eu pe madam Popescu.\n" +
            "— Vai de mine! la noi se fumează... Bărbatu-meu fumează... și... dumnealui... mi se pare că-i cam place.\n" +
            "    Și zicând „dumnealui\", mama mi-arată râzând pe domnul maior.\n" +
            "— A! zic eu, și dumnealui?\n" +
            "— Da, da, dumnealui! să-l vezi ce caraghios e cu țigara-n gură, să te prăpădești de râs... ca un om mare...\n" +
            "— A! asta nu e bine, domnule maior, zic eu; tutunul este o otravă...\n" +
            "— Da tu de ce tragi? mă-ntrerupe maiorul lucrând cu lingura în cheseaua de dulceață...\n" +
            "— Ajunge, Ionel! destulă dulceață, mamă! iar te-apucă stomacul...\n",
    },
    {
        title: "Exercițiul nr. 8",
        author: "Ion Luca CARAGIALE, D-l Goe",
        text: "În sfârșit, iacătă conductorul cu biletul: primește paralele și liberează pe captiv, pe care toate trei cocoanele îl sărută dulce, ca și cum l-ar revedea după o îndelungată absență. Și mam' mare se hotărăște să stea în coridor, pe un geamantan străin, să păzească pe Goe, să nu se mai întâmple ceva puișorului. Puișorul vede o linie de metal în colțul coridorului, care are la capătul de sus o mașină cu mâner. Se suie-n picioare pe geamantan, pune mâna pe mânerul mașinii și începe să-l tragă.\n" +
            "– Șezi binișor, puișorule! să nu strici ceva! zice mam' mare...\n",
    },
    {
        title: "Exercițiul nr. 9",
        author: "Barbu Ștefănescu-DELAVRANCEA, Bunica",
        text: "Glasul ei dulce mă legăna; genile mi se prindeau și adormeam; uneori tresăream ș-o întrebam câte ceva; ea începea să spuie, și eu visam înainte.\n" +
            "– A fost odată un împărat mare, mare...\n" +
            "– Cât de mare?\n" +
            "– Mare de tot. Și-și iubea împărăteasa ca ochii din cap. Dar copii nu avea. Și îi părea rău, îi părea rău că nu avea copii...\n" +
            "– Bunico, e rău să nu ai copii?\n" +
            "– Firește că e rău. Casa omului fără copii e casă pustie.\n" +
            "– Bunico, dar eu n-am copii și nu-mi pare rău.\n" +
            "    Ea lăsa fusul, râdea, îmi dăsfăcea părul cârlionțat în două și mă săruta în creștetul capului.\n",
    },
    {
        title: "Exercițiul nr. 10",
        author: "Barbu Ștefănescu-DELAVRANCEA, Hagi Tudose",
        text: "– De când eram d-o șchioapă pricepusem lumea, le zicea el. Înțelesesem bine de tot că o cârpă din gunoi este o muncă de om pe care te faci stăpân dacă o pui doparte. Și dacă mama îmi dădea un ban de trei ca să-mi iau un simit, eu mă uitam în ghiozdan: de aveam felia de pâne, sănătate bună, aveam ce mânca. Nu te saturi cu pâne? Ce-ți trebuie simiți? Și puneam banul bine. Și un ban peste altul fac doi, peste doi dacă pui altul, fac trei... Râdeți voi... râdeți... Da', vânturați banii în mâni și veți simți ce răcoare ține când vă e cald, și ce cald când vă e frig. E destul să te gândești ce poți face cu banii, ca să și guști bucuria lucrului pe care nu l-ai cumpărat. Ai simțit bucuria?... De ce să-l mai cumperi?... Râdeți voi, râdeți... Ce lucru poate fi mai luminat ca un jeratic de galbeni întinși pe o masă?... Voi râdeți... râdeți cu hohote... Niște risipitori... În viața voastră n-o să gustați adevărata bucurie...",
    },
    {
        title: "Exercițiul nr. 11",
        author: "Ionel TEODOREANU, Ulița copilăriei",
        text: "E o umilă şi neştiută uliţă de margine de târg.\n" +
            "    Nu răsfaţă ochiul cu mlădieri de râu gătite-n salturi de verdeaţă şi nici nu ispiteşte pasul cu caldarâm sonor. E firavă şi goală: numai pământ şi pietre.\n" +
            "    Dau năvală celelalte uliţi, înghesuite una într-alta, cot la cot, s-ajungă mai degrabă la uliţa cea mare, cu pântec de piaţă, căreia îi duc larma grămădită pe tăvi de piatră.\n" +
            "    Ea e pustnică. A strâns pios tăcerea aruncată de celelalte uliţi şi s-a dat la o parte de ele. Năzuia poate să iasă din târg, să se îndrume departe, spre munţi: să fie acolo o potecă prin brădet. Dar au părăsit-o puterile la marginea târgului şi s-a lungit la pământ în dreptul bisericii, a câtorva căsuţe liniştite, alături de căsuţa bunicilor şi de a noastră. Acolo a rămas.\n",
    },
    {
        title: "Exercițiul nr. 12",
        author: "Costache NEGRUZZI, Cum am învățat românește",
        text: "Pe când uitasem că suntem români și că avem și noi o limbă, pe când ne lipsea și cărți și tipografie; pe când toată lumea se aruncase în dasii și perispomeni ca babele în căței și motani, căci la școala publică se învăța numai grecește; când, în sfârșit, literatura română era la darea sufletului, câțiva boieri, ruginiți în românism, neputându-se deprinde cu frumoasele ziceri: parigorisesc, catadicsesc ș.c.l., toate în esc, create de diecii vistieriei, pentru că atunci între ei se plodea geniul, ședeau triști și jăleau pierderea limbii, uitându-se cu dor spre Buda sau Brașov, de unde le veneau pe tot anul calendare cu povești la sfârșit, și din când în când câte o broșură învățătoare meșteșugului de a face zahăr din ciocălăi de cucuruzi, sau pâine și crohmală de cartofe.\n",
    },
    {
        title: "Exercițiul nr. 13",
        author: "Calistrat HOGAȘ, Pe drum de munte",
        text: "Desigur că speriatul nostru cicerone ne va fi urmat multă vreme cu privirea. Noi însă nu ne mai uitarăm îndărăt, ci ne urmarăm drumul pe prundiș, sărind ca niște capre peste bolovani, cursuri de apă, bălți și gloduri. Dimineața era măreață și ne vestea o adevărată zi de iulie. Nici un nor nu plutea sub albastrul cerului adânc și limpede ca ochiul unei fecioare. Toate stelele se mistuiseră și cel mult dacă îndrăzneața Stea a Ciobanului mai înfrunta, din când în când, valurile de lumină trandafirie, cu care zorile inundau răsăritul depărtat. Nici un vânt nu mișca aerul, și grâul, în lanurile de aur, stătea tot atât de neclintit ca și firul de iarbă din fânețele înflorite. Roua avea înfățișarea unor mărgăritare ce nu așteptau decât o rază de soare spre a se schimba în strălucitoare diamante. Umezeala și răcoarea dimineții dădeau întregii firi o nespusă frăgezime. Totul începuse a se deștepta sub întâile raze ale zilei, dar totul era cuprins de o liniște, de o tăcere, de un fel de înmărmurire solemnă: și firea întreagă părea că se află în o religioasă așteptare. La răsărit se ivea geana de aur și de purpură a unui soare tânăr, care într-o clipă șterse într-o nemăsurată depărtare umbra de pe fruntea munților neguroși. Noi ne urmarăm drumul cu sufletul stăpânit de măreția priveliștii, iar rarii drumeți ce întâlneam, și pe care-i lăsam în urmă, după ce ne doreau cale bună, se uitau lung și mirați după noi.",
    },
    {
        title: "Exercițiul nr. 14",
        author: "Mircea ELIADE, Romanul adolescentului miop",
        text: "Romanul îl voi scrie altfel. Eroul sunt eu, firește. Mă tem, însă, că viața mea - stinsă între copii și cărți - nu va interesa cititorii. Pentru mine, tot ceea ce nu am avut, tot ceea ce am dorit din mansardă, în înserări calde și tulburătoare, prețuiește mai mult decât anii tovarășilor mei risipiți în jocuri, în sărbători de familie și idile.\n" +
            "    Dar cititorii?... înțeleg și eu că toată durerea unui adolescent miop nu va mișca nici un suflet dacă acest adolescent nu se va îndrăgosti și nu va suferi. De aceea, m-am și gândit la un personaj pe care la început îl numisem Olga.\n" +
            "    I-am povestit lui Dinu tot ceea ce ar trebui să aibă loc prin prezența acestei fete. Dinu m-a întrerupt și m-a rugat: dacă într-adevăr îi sunt prieten, sa schimb numele eroinei în Laura.\n",
    },
    {
        title: "Exercițiul nr. 15",
        author: "Antoine de SAINT-EXUPÉRY, Micul prinț",
        text: "Desenul meu e însă, bineînţeles, mult mai puţin fermecător decât modelul. Nu e vina mea. Pe când aveam şase ani, oamenii mari mă făcuseră să-mi pierd orice încredere în cariera mea de pictor şi, în afară de şerpi boa întregi şi de şerpi boa spintecaţi, nu mai învăţasem să desenez nimic altceva. Prin urmare, priveam cu ochi mari de uimire la arătarea aceea. Nu uitaţi că mă aflam la mii de leghe depărtare de orice tărâm locuit. Or, omuleţul meu nu părea a fi nici rătăcit, nici mort de oboseală, nici mort de foame, mici mort de sete, nici mort de frică. Nu avea deloc înfăţişarea unui copil pierdut în inima pustiului, la mii de leghe depărtare de orice tărâm locuit. Când, în sfârşit, am fost în stare să vorbesc, l-am întrebat:\n" +
            "    − Bine, dar... ce cauţi tu aici?\n" +
            "    Iar el rosti atunci din nou, încetişor, cerându-mi parcă un lucru din cale-afară de însemnat:\n" +
            "    − Te rog... desenează-mi o oaie!\n",
    },
    {
        title: "Exercițiul nr. 16",
        author: "Lewis CARROL, Alice în Țara Minunilor",
        text: "Fie că era o fântână foarte adâncă, fie că Alice cădea foarte, foarte încet, oricum, avu timp, în cădere, să privească pe îndelete în juru-i şi să se întrebe cu mirare ce-avea să urmeze. Mai întâi încercă să privească în jos, spre a se lămuri unde va ajunge, dar era prea întuneric ca să poată deosebi ceva; apoi se uită la pereţii fântânii şi băgă de seamă că erau acoperiţi de rafturi cu provizii şi de etajere cu cărţi; ici şi colo văzu hărţi şi tablouri agăţate de piroane. În trecere, luă de pe un raft un borcan, cu o etichetă pe care scria: DULCEAŢĂ DE PORTOCALE, dar, spre marea ei dezamăgire, când îl deschise, îl găsi gol. Nu vru să-i dea drumul, de teamă să nu omoare pe cineva dedesubt, şi izbuti să aşeze borcanul într-un alt raft, pe lângă care luneca.",
    },
    {
        title: "Exercițiul nr. 17",
        author: "Mark TWAIN, Aventurile lui Tom Sawyer",
        text: "Ocoli cu băgare de seamă casele de la drum şi ajunse într-o ulicioară noroioasă, care trecea prin dosul grajdului mătuşii. Trecu dincolo de zona periculoasă în care putea fi prins şi pedepsit. Se îndreptă spre piaţa publică a târgului, unde două „armate\" de băieţi aveau întâlnire pentru a-şi măsura forţele, conform unei înţelegeri prealabile. Tom era generalul-comandant al uneia din oşti, Joe Harper (prietenul său cel mai bun) era căpetenia celeilalte. Aceşti doi mari comandanţi de oaste nu se înjoseau să ia ei înşişi parte la luptă, asta era treaba răcanilor; aşezaţi pe o movilă, conduceau amândoi operaţiile, transmiţând ordine prin aghiotanţi. Oastea lui Tom cuceri o mare victorie, după o luptă îndelungată şi aprigă. Apoi se numărară morţii, se făcu schimb de prizonieri şi se căzu de acord asupra condiţiilor conflictului următor, stabilindu-se şi ziua bătăliei ce devenea necesară − după care ostile se aliniară şi părăsiră încolonate câmpul de bătaie, iar Tom se îndreptă singur spre casă.",
    },
    {
        title: "Exercițiul nr. 18",
        author: "Rudyard KIPLING, Cărțile junglei",
        text: "Era ora șapte a unei foarte calde seri pe dealurile Seeonee când Tata Lup s-a trezit din somnul său de după-amiază, se scărpină, căscă și își scutură labele, pentru a scăpa de senzația de amorțeală din vârfurile degetelor. Mama Lup stătea cu nasul ei mare, gri căzut lângă ai săi patru pui, care făceau tumbe și chelălăiau, și luna strălucea prin gura peșterii unde locuiau ei.\n" +
            "– Mrrrr! zise Tata Lup, este vremea să mergem la vânătoare din nou.\n",
    },
    {
        title: "Exercițiul nr. 19",
        author: "Alphonse DAUDET, Picul",
        text: "M-am născut la 13 mai 18, într-un oraş din Languedoc unde, ca în toate oraşele din sud, găseşti mult soare, destul colb, o mănăstire de carmelite şi două sau trei monumente romane. Tatăl meu, domnul Eyssette, care pe vremea aceea făcea negoţ cu fulare, avea la marginea oraşului o fabrică mare, cu o aripă în care-şi întocmise o locuinţă tihnită, adumbrită de platani şi despărţită de ateliere printr-o grădină foarte întinsă. Acolo am văzut lumina zilei şi mi-am petrecut cei dintâi ani din viaţa mea. Aşa că, memoria mea, recunoscătoare, a păstrat grădinii, fabricii şi platanilor, o nepieritoare amintire, iar când părinţii mei şi-au pierdut averea şi a trebuit să mă despart de lucrurile acestea, mi-a părut rău după ele întocmai ca după nişte fiinţe.",
    },
    {
        title: "Exercițiul nr. 20",
        author: "Daniel DEFOE, Robinson Crusoe",
        text: "Fiind al treilea fiu şi nepregătit pentru vreo meserie, capul mi s-a umplut de timpuriu cu tot felul de gânduri năstruşnice. Tata, om mai în vârstă, mi-a dat o creştere aleasă. Am învăţat acasă şi apoi la şcoala din oraş, tata voind să mă facă om de legi. Eu însă nu mă gândeam la nimic altceva decât să plec pe mare, şi această aprigă dorinţă mă făcea să înfrunt toate poveţele tatei, ba chiar şi poruncile lui, precum şi îndrumările şi rugăminţile mamei şi ale prietenilor mei. O ursită rea părea să mă mâne în această năzuinţă a firii mele, îndreptându-mă către acea viaţă năpăstuită, de care trebuia să am parte mai târziu.",
    },
    {
        title: "Exercițiul nr. 21",
        author: "Liviu Rebreanu - Ion",
        text: "— Nu râde la front, măgarule! se răstește Macedon. Na sticla și dă fuga la cantină la Avrum, să-ți mai dea o porție pentru mine!... Înțeles, căprar?",
    },
        {
            title: "Exercițiul nr. 22",
            author: "Marielle Tabart, Brâncuși, inventatorul sculpturii moderne",
            text: "Prima Muză adormită, (din marmură) mai are încă ceva din fizionomia modelului ochii închiși ating o suprafață netedă, " +
                "gura este întredeschisă singurul relief marcant find creasta nasului țâșnește din oval și-i delimitează versanții care " +
                "lunecă dea lungul sprâncenelor întinse sub plete (dedesupt) alături ca un omagiu a lui Man Ray. " +
                "Două capete ovale, față corp se aseamănă prin ciudate afinități.",
        },
        {
            title: "Exercițiul nr. 23",
            author: "Jose Ortega Y Gasset, Studii despre iubire - Iubirea la Stendhal",
            text: "Teoreticianul ajunge la formula doctrinară mânat de o năzuință exasperată de a coincide cu realitatea. " +
                "Uzează în acest scop de nesfârșite precauții, una din ele fiind menținerea unității riguroase și a coeziunii între " +
                "numeroasele lui idei.",
            info: "Ed Humanitas, 2012, Pag 18,",
        },
        {
            title: "Exercițiul nr. 24",
            author: "Jose Ortega Y Gasset, Studii despre iubire - Iubirea la Stendhal",
            text: "Cartea oferă o lectură delectabilă. Stendhal povestește întotdeauna, chiar și când definește, raționează și teoretizează. " +
                "După gustul meu, e cel mai bun narator din câți există în fața Celui De Sus. " +
                "Dar faimoasa lui teorie despre dragostea ca cristalizare e oare sigură? De ce oare n-a fost supusă unui studiu aprofundat? " +
                "Deși e întoarsă și pe față, și pe dos, nimeni nu o supune unei analize adecvate.",
            info: "Ed Humanitas, 2012, Pag 19,",
        },
        {
            title: "Exercițiul nr. 25",
            author: "Jose Ortega Y Gasset, Studii despre iubire - Alte eseuri înrudite",
            text: "Doamnă, excursia a fost delicioasă. " +
                "Rău este că, după ce ne-ați condus prin tracțiune spirituală, " +
                "ne lăsați acum singuri și, abandonați propriei noastre ponderalități, ce altceva putem face decât să coborâm?",
            info: "Ed Humanitas, 2012, Pag 140,",
        },
        {
            title: "Exercițiul nr. 26",
            author: "Jose Ortega Y Gasset, Studii despre iubire - Alte eseuri înrudite",
            text: "Idealul e o funcție vitală, unul dintre nenumăratele instrumente ale vieții. " +
                "Etica și estetica pot defini în orice moment ce figuri merită să funcționeze ca idealuri, " +
                "dar care anume e misiunea însăși a idealului nu ne-o poate arăta decâi biologia.",
            info: "Ed Humanitas, 2012, Pag 126,",
        },
]
module.exports =  { text, shortText, exercitii };
