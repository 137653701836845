import React, { useState, useEffect } from 'react';

import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import axios from "axios"
import seed from '../data/seed'
import ReactTooltip from 'react-tooltip'

const mainColor = '#0123da';

const CancelToken = axios.CancelToken;
let cancel;

let displayPOS = [
    {posPrefix: 's', display: 'substantiv', color: '#7474de'},
    {posPrefix: 'adj', display: 'adjectiv', color: 'green'},
    {posPrefix: 'pron', display: 'pronume', color: 'orange'},
    {posPrefix: 'num', display: 'numeral', color: 'orange'},
    {posPrefix: 'v', display: 'verb', color: '#8bc34a'},
    {posPrefix: 'adv', display: 'adverb', color: 'green'},
    {posPrefix: 'prep', display: 'prepoziție', color: 'orange'},
    {posPrefix: 'conj', display: 'conjuncție', color: 'orange'},
    {posPrefix: 'interj', display: 'interjecție', color: 'darkgreen'},
    {posPrefix: 'loc', display: 'locuțiune', color: 'darkgreen'},
    {posPrefix: 'e', display: 'expresie', color: 'orange'},
];

let displayPOSByPrefix = displayPOS.reduce((total, currentValue, currentIndex, arr) => {
    total[currentValue.posPrefix] = currentValue;
    return total;
}, {});

const RulesPage = (props) => {
    console.log('RulesPage', props)

    const [loading, setLoading] = useState(false);
    const [loaded, setLoaded] = useState(!!props.data);
    const [text, setText] = useState(props.text || seed.shortText);
    const [data, setData] = useState(props.data || []);
    const [activePOS, setActivePOS] = useState({'v': true});
    const [error, setError] = useState(null);
    const [errorCode, setErrorCode] = useState(0);

    useEffect(() => {
        fetchData();
    }, []); // passing an empty array as second argument triggers the callback in useEffect only after the initial render thus replicating `componentDidMount` lifecycle behaviour

    useEffect(() => {
        ReactTooltip.rebuild()
    });

    const fetchData = () => {
        if (data && data.length > 0) {
            console.log("Already loaded");
            return;
        }
        setLoading(true);
        setError(null);
        setData([]);
        if (cancel !== undefined) {
            console.log('canceling req');
            cancel();
        } else {
            console.log('no req to cancel');
        }
        axios
            .post(
                `https://www.analiza-gramaticala.ro:4443/grammar/` ,
                {text},
                {
                    cancelToken: new CancelToken(function executor(c) {
                        cancel = c;
                        console.log('received cancel token ', cancel, c)
                    })
                }
            ).then(({ data }) => {
                console.log('response', data);
                setLoading(false);
                setLoaded(true);
                setData(data.output);
                setError(null);
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    console.log('Request canceled');
                } else {
                    if (error && error.response && error.response.status) {
                        setErrorCode(error.response.status);
                    }
                    setLoading(false);
                    setError(error);
                }
            });
    }

    const onTextChanged = (text) => {
        if (cancel !== undefined) {
            cancel();
            cancel = undefined;
        }
        setText(text);
        setData([]);
        setLoaded(false);
        setLoading(false);

        if ((text || '').length > 2000) {
            setError('Textul este prea mare');
            setErrorCode(413);
        } else {
            setError(null);
            setErrorCode(0);
        }
    }
    let btnStyleBase = {
        margin: '5px',
        border: '1px gray solid',
        padding: '6px',
        whiteSpace: 'nowrap',
        cursor: 'pointer',
    }
    let btnStyleSelected = Object.assign({}, btnStyleBase, {
        background: mainColor,
        color: 'white',
        display: 'inline-block',
    });
    let btnStyleUnselected = Object.assign({}, btnStyleBase, {
        background: 'white',
        color: mainColor,
        display: 'inline-block',
    });
    const onPosClick = (dp) => {
        let oldState = activePOS[dp.posPrefix];
        let newValue = Object.assign({}, activePOS, {[dp.posPrefix]: !oldState})
        setActivePOS(newValue);
    }
    const renderDisplayPOS = (dp) => {
        return (
            <span onClick={() => onPosClick(dp)} style={activePOS[dp.posPrefix] ? btnStyleSelected : btnStyleUnselected}>
                {dp.display}
            </span>
        )
    }

    const describePos = (e) => {
        if (!e || !e.posDesc) {
            return null;
        }
        let posDesc = e.posDesc;
        if (posDesc.length <= 1) {
            return posDesc.join(', ');
        }
        return "indecis: " + posDesc.slice(0, -1).join(', ') + ' sau ' + posDesc.slice(-1)[0];
    };
    const renderItem = (e) => {

        if (e.word === '\n') {
            return <p/>
        }
        if (e.word === ' ') {
            return ' '
        }
        let hl = (e.pos || []).find(p => activePOS[p]);
        let posDesc = describePos(e);
        if (!hl) {
            return <span  data-tip={posDesc}  >{e.word}</span>;
        }
        let def = {
            whiteSpace: 'nowrap',
            cursor: 'pointer',
        };
        if (e.word !== ' ' && !e.word.endsWith(' ')) {
            def.display = 'inline-block';
        }
        let color = (displayPOSByPrefix[(e.pos||[])[0]] || {color: 'red'}).color;
        // color = 'blue';

        let s =  Object.assign({}, def, {
            border: (e.ambig === 'NO' ? '1px solid ' : '1px dashed ') + color,
            color: 'black',
            padding: '3px',
            borderRadius: '15px'
        })
        // let style = hl && (e.ambig === 'NO' || e.ambig === 'YES') ? {backgroundColor: 'orange'} : {};
        return <span  data-tip={posDesc} style={s}>{e.word}</span>
    }

    return <Layout  location={props.location}>

        <SEO title="Analizator" description="Analiză gramaticală pentru limba română. Verbe, substantive, adjective, adverbe"/>


        {!props.isDemo && <div>
            <h3>Analizator</h3>


            <p>
            Scrieți sau copiați aici textul vostru.
            </p>
            <p>
            Pentru o analiză de calitate, se impune folosirea diacriticelor.
            </p>
            <p>
            În sfera verbului, sunt analizate: adverbele negative, pronumele personale și reflexive (cliticele),
            adverbele fără funcție sintactică (semiadverbele) care nuanțează (intensifică, cuantifică) acțiunea exprimată de verb.
            </p>


            <textarea  cols="100" rows="5" value={text} onChange={e => onTextChanged(e.target.value)} style={{maxWidth: '100%', border: `1px solid ${mainColor}`}}/>
            <br/>
            <input type={'button'} value={'Analizează'} onClick={fetchData} style={{background: mainColor, color: 'white',}}/>

            <br/>
            <br/>
            {loading && <div>procesare...</div>}
            {error && <div style={{color: 'red'}}>
                {errorCode === 413 ? <div> Textul este prea mare </div> : <div> Eroare: {error + ''} </div>}
            </div>}
            {!loaded && !loading && <div style={{fontSize: 'small'}}>După introducerea textului, apăsați butonul "Analizează"</div>}
        </div>}


        {loaded && [
            props.isDemo ? <div> <h3>Exemplificare analiză <i>{props.textAuthor}, {props.textTitle}</i></h3> </div> :
            <h3 style={{marginBottom: '0.5em',}}>Rezultatul analizei:</h3>
            ,
            <div style={{fontSize: 'smaller'}}>
                <i>Selectați oricare din aceste categorii și anulați selecția la categoriile care nu vă interesează. Unitățile izolate cu linie întreruptă indică indecizia (ambiguitate).</i>
            </div>
            ,

            <div style={{flex: '1', paddingBottom: '21px', fontSize: 'smaller'}}>
                {displayPOS.map(dp => renderDisplayPOS(dp))}
            </div>
            ,
            <div>
                {data.map(crt => renderItem(crt))}
            </div>
            ,
            <br/>,
            <div><i>Comunică-ne părerea ta pe <a href="https://forum.analiza-gramaticala.ro/" target={"forum"}>forum</a></i></div>,
            <br/>,
            <br/>,
            <ReactTooltip place="bottom"/>,
        ]}

        <br/>
        <br/>
        <br/>
        <br/>
    </Layout>
}

export default RulesPage
